

/****************************************************************************
1. CSS Reset
****************************************************************************/

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address,
big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl,
dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas,
details, embed,  figure, figcaption, footer, header, hgroup,  menu, nav, output, ruby, section, summary, time, mark, audio,
video {	margin: 0; 	padding: 0;	border: 0;	font-size: 100%;	font: inherit;	vertical-align: baseline;}
article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section { display: block; }
ol, ul { list-style: none;}
blockquote, q {	quotes: none;}
blockquote:before, blockquote:after, q:before, q:after { content: ''; content: none; }
table {	border-collapse: collapse; border-spacing: 0; }

/******************************************************************
my added hacks (gavin)
**********************************************************************/
.distance-range {font-size:20px;}
.band-page{font-size:30px; vertical-align:top;}

.promotag {
    font-size:20px;
    color: #e74c3c;

    font-family: "Droid Serif",serif;
}

.venuename {
    color: #fffff;
    text-shadow: 0px 1px 2px #000;
}

.eventdate {
    font-size:14px;
    text-shadow: 0px 1px 2px #000;
}

.buttontag {
    font-size:15px;
    color: #ffffff;
    font-family: "OpenSansLight", Arial, Helvetica,sans-serif;
    font-weight:400;
}


.logotag {
    font-size:38px;
    color: #ffffff;
    font-family:'Righteous', Arial, serif; font-weight: 400;
}


.promo {
    font-size:19px;
//color: #e74c3c;
    vertical-align: middle;
    padding-left: 1px;
    padding-right: 1px;

}

.promotext {
    color: #e74c3c;

}

.promofestival {
    font-size:20px;
    color: #ffffff;
    background-color: #e74c3c;
    vertical-align: top;
    padding-left: 1px;
    padding-right: 1px;
}

.socialdiv {
    margin-top:8px;
    display: inline-block;
    height:45px;
    width:100%;
}

.fb-share-button {
    vertical-align: top;
}
.fb_iframe_widget span {
    vertical-align: text-top !important;
}

.twitter-share-button {    vertical-align: top;
}

.searchbutton {
    background-color:#ffffff;
    background-repeat:no-repeat;
    border: 2px #e74c3c solid;
    padding:4px;
    color: #ffffff;
    font-size: 16px;
    cursor:pointer;
    overflow: hidden;
    outline:none;
}

.searchbutton:hover {
    color: #fff;
    background-color: #e74c3c;
}

/* css for the shiny buttons */
.btn {
    alignment-adjust:after-edge;
    cursor: pointer;
    width:20px;
    margin: 4px;
    border-radius: 3px;
    text-decoration: none;
    padding: 4px;
    font-size: 16px;
    transition: .3s;
    -webkit-transition: .3s;
    -moz-transition: .3s;
    -o-transition: .3s;
    display: inline-block;
    display:none;
}

/* css for the shiny buttons */
.btn-lgr {
    alignment-adjust:middle;
    cursor: pointer;
    width:90%;
    margin: 4px;
    border-radius: 3px;
    text-decoration: none;
    padding: 6px;
    font-size: 16px;
    transition: .3s;
    -webkit-transition: .3s;
    -moz-transition: .3s;
    -o-transition: .3s;
    display: inline-block;

}

.btn-lgr-tickets {
    alignment-adjust:middle;
    cursor: pointer;
    width:90%;
    margin: 2px;
    border-radius: 3px;
    text-decoration: none;
    padding: 6px;
    font-size: 18px;
    transition: .3s;
    -webkit-transition: .3s;
    -moz-transition: .3s;
    -o-transition: .3s;
    display: inline-block;

}

.dates { alignment-adjust: after-edge;
    display: inline-block;

}
.red {
    color: #e74c3c;
    border: 2px #e74c3c solid;
}

.red:hover {
    color: #fff;
    background-color: #e74c3c;
}

.grn {
    color: #fff;
    background-color: #48aa25;
}

.grn:hover {
    color:#fff;
    background-color: #48aa25;
}

.buybtn {
    background: #48aa25;
    background-image: -webkit-linear-gradient(top, #48aa25, #32CD32);
    background-image: -moz-linear-gradient(top, #48aa25, #32CD32);
    background-image: -ms-linear-gradient(top, #48aa25, #32CD32);
    background-image: -o-linear-gradient(top, #48aa25,#32CD32);
    background-image: linear-gradient(to bottom, #48aa25, #32CD32);
    -webkit-border-radius: 28;
    -moz-border-radius: 28;
    border-radius: 28px;
    font-family: Arial;
    color: #ffffff;
    font-size: 20px;
    padding: 10px 20px 10px 20px;
    text-decoration: none;
}

.buybtn:hover {
    background: #3cb0fd;
    background-image: -webkit-linear-gradient(top, #48aa25,#48aa25);
    background-image: -moz-linear-gradient(top, #48aa25, #48aa25);
    background-image: -ms-linear-gradient(top,#48aa25,#48aa25);
    background-image: -o-linear-gradient(top, #48aa25, #48aa25);
    background-image: linear-gradient(to bottom, #48aa25, #48aa25);
    text-decoration: none;
}


.redhighlight {
    color: #fff;
    background-color: #e74c3c;
}

.highlight {
    background-color: #D3D3D3;
    font-weight: bold;
    padding:3px;
}

.ticket {
    alignment-adjust:after-edge;
    cursor: pointer;
    margin: 4px;
    border-radius: 3px;
    text-decoration: none;
    padding: 4px;
    font-size: 16px;
    transition: .3s;
    -webkit-transition: .3s;
    -moz-transition: .3s;
    -o-transition: .3s;
    display: inline-block;
}

.ticket button{
    color:#ffffff;
}

/* Absolute Center CSS Spinner */
.loading {
    position: fixed;
    z-index: 999;
    height: 2em;
    width: 2em;
    overflow: show;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

/* Transparent Overlay */
.loading:before {
    content: '';
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.3);
}

/* :not(:required) hides these rules from IE9 and below */
.loading:not(:required) {
    /* hide "loading..." text */
    font: 0/0 a;
    color: transparent;
    text-shadow: none;
    background-color: transparent;
    border: 0;
}

.loading:not(:required):after {
    content: '';
    display: block;
    font-size: 15px;
    width: 1em;
    height: 1em;
    margin-top: -0.5em;
    -webkit-animation: spinner 1500ms infinite linear;
    -moz-animation: spinner 1500ms infinite linear;
    -ms-animation: spinner 1500ms infinite linear;
    -o-animation: spinner 1500ms infinite linear;
    animation: spinner 1500ms infinite linear;
    border-radius: 0.5em;
    -webkit-box-shadow: rgba(0, 0, 0, 0.75) 1.5em 0 0 0, rgba(0, 0, 0, 0.75) 1.1em 1.1em 0 0, rgba(0, 0, 0, 0.75) 0 1.5em 0 0, rgba(0, 0, 0, 0.75) -1.1em 1.1em 0 0, rgba(0, 0, 0, 0.5) -1.5em 0 0 0, rgba(0, 0, 0, 0.5) -1.1em -1.1em 0 0, rgba(0, 0, 0, 0.75) 0 -1.5em 0 0, rgba(0, 0, 0, 0.75) 1.1em -1.1em 0 0;
    box-shadow: rgba(0, 0, 0, 0.75) 1.5em 0 0 0, rgba(0, 0, 0, 0.75) 1.1em 1.1em 0 0, rgba(0, 0, 0, 0.75) 0 1.5em 0 0, rgba(0, 0, 0, 0.75) -1.1em 1.1em 0 0, rgba(0, 0, 0, 0.75) -1.5em 0 0 0, rgba(0, 0, 0, 0.75) -1.1em -1.1em 0 0, rgba(0, 0, 0, 0.75) 0 -1.5em 0 0, rgba(0, 0, 0, 0.75) 1.1em -1.1em 0 0;
}

/* Animation */

@-webkit-keyframes spinner {
    0% {
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@-moz-keyframes spinner {
    0% {
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@-o-keyframes spinner {
    0% {
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@keyframes spinner {
    0% {
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
/****************************************************************************
2. Font Face
****************************************************************************/

@font-face {
    font-family: 'EntypoRegular';
    src: url('../fonts/entypo-webfont.eot');
    src: url('../fonts/entypo-webfont.eot?#iefix') format('embedded-opentype'),
    url('../fonts/entypo-webfont.woff') format('woff'),
    url('../fonts/entypo-webfont.ttf') format('truetype'),
    url('../fonts/entypo-webfont.svg#EntypoRegular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'PWNewArrows';
    src: url('../fonts/PWNewArrows.eot');
    src: url('../fonts/PWNewArrows.eot?#iefix') format('embedded-opentype'),
    url('../fonts/PWNewArrows.woff') format('woff'),
    url('../fonts/PWNewArrows.ttf') format('truetype'),
    url('../fonts/PWNewArrows.svg#EntypoRegular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'OpenSansLight';
    src: url('../fonts/OpenSans-Light-webfont.eot');
    src: url('../fonts/OpenSans-Light-webfont.eot?#iefix') format('embedded-opentype'),
    url('../fonts/OpenSans-Light-webfont.woff') format('woff'),
    url('../fonts/OpenSans-Light-webfont.ttf') format('truetype'),
    url('../fonts/OpenSans-Light-webfont.svg#OpenSansLight') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'OpenSansLightItalic';
    src: url('../fonts/OpenSans-LightItalic-webfont.eot');
    src: url('../fonts/OpenSans-LightItalic-webfont.eot?#iefix') format('embedded-opentype'),
    url('../fonts/OpenSans-LightItalic-webfont.woff') format('woff'),
    url('../fonts/OpenSans-LightItalic-webfont.ttf') format('truetype'),
    url('../fonts/OpenSans-LightItalic-webfont.svg#OpenSansLightItalic') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: 'OpenSansRegular';
    src: url('../fonts/OpenSans-Regular-webfont.eot');
    src: url('../fonts/OpenSans-Regular-webfont.eot?#iefix') format('embedded-opentype'),
    url('../fonts/OpenSans-Regular-webfont.woff') format('woff'),
    url('../fonts/OpenSans-Regular-webfont.ttf') format('truetype'),
    url('../fonts/OpenSans-Regular-webfont.svg#OpenSansRegular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'OpenSansItalic';
    src: url('../fonts/OpenSans-Italic-webfont.eot');
    src: url('../fonts/OpenSans-Italic-webfont.eot?#iefix') format('embedded-opentype'),
    url('../fonts/OpenSans-Italic-webfont.woff') format('woff'),
    url('../fonts/OpenSans-Italic-webfont.ttf') format('truetype'),
    url('../fonts/OpenSans-Italic-webfont.svg#OpenSansItalic') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'OpenSansSemibold';
    src: url('../fonts/OpenSans-Semibold-webfont.eot');
    src: url('../fonts/OpenSans-Semibold-webfont.eot?#iefix') format('embedded-opentype'),
    url('../fonts/OpenSans-Semibold-webfont.woff') format('woff'),
    url('../fonts/OpenSans-Semibold-webfont.ttf') format('truetype'),
    url('../fonts/OpenSans-Semibold-webfont.svg#OpenSansSemibold') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'OpenSansSemiboldItalic';
    src: url('../fonts/OpenSans-SemiboldItalic-webfont.eot');
    src: url('../fonts/OpenSans-SemiboldItalic-webfont.eot?#iefix') format('embedded-opentype'),
    url('../fonts/OpenSans-SemiboldItalic-webfont.woff') format('woff'),
    url('../fonts/OpenSans-SemiboldItalic-webfont.ttf') format('truetype'),
    url('../fonts/OpenSans-SemiboldItalic-webfont.svg#OpenSansSemiboldItalic') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'OpenSansBold';
    src: url('../fonts/OpenSans-Bold-webfont.eot');
    src: url('../fonts/OpenSans-Bold-webfont.eot?#iefix') format('embedded-opentype'),
    url('../fonts/OpenSans-Bold-webfont.woff') format('woff'),
    url('../fonts/OpenSans-Bold-webfont.ttf') format('truetype'),
    url('../fonts/OpenSans-Bold-webfont.svg#OpenSansBold') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'OpenSansBoldItalic';
    src: url('../fonts/OpenSans-BoldItalic-webfont.eot');
    src: url('../fonts/OpenSans-BoldItalic-webfont.eot?#iefix') format('embedded-opentype'),
    url('../fonts/OpenSans-BoldItalic-webfont.woff') format('woff'),
    url('../fonts/OpenSans-BoldItalic-webfont.ttf') format('truetype'),
    url('../fonts/OpenSans-BoldItalic-webfont.svg#OpenSansBoldItalic') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'OpenSansExtrabold';
    src: url('../fonts/OpenSans-ExtraBold-webfont.eot');
    src: url('../fonts/OpenSans-ExtraBold-webfont.eot?#iefix') format('embedded-opentype'),
    url('../fonts/OpenSans-ExtraBold-webfont.woff') format('woff'),
    url('../fonts/OpenSans-ExtraBold-webfont.ttf') format('truetype'),
    url('../fonts/OpenSans-ExtraBold-webfont.svg#OpenSansExtrabold') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'OpenSansExtraboldItalic';
    src: url('../fonts/OpenSans-ExtraBoldItalic-webfont.eot');
    src: url('../fonts/OpenSans-ExtraBoldItalic-webfont.eot?#iefix') format('embedded-opentype'),
    url('../fonts/OpenSans-ExtraBoldItalic-webfont.woff') format('woff'),
    url('../fonts/OpenSans-ExtraBoldItalic-webfont.ttf') format('truetype'),
    url('../fonts/OpenSans-ExtraBoldItalic-webfont.svg#OpenSansExtraboldItalic') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

/****************************************************************************
3. BASIC STYLES
****************************************************************************/

html { height: 100%; overflow-x:hidden; }

h1{ font-family: 'OpenSansLight', Arial, serif; font-weight: 400; text-align: right; text-shadow: 0px 1px 2px #000; }

h2, h3, h4, h5, h6, p { font-family: "OpenSansLight", Arial, Helvetica,sans-serif; line-height: 1.5; }

p { padding-left: 3px; padding-right: 3px;}
h1{ font-size:35px; }
h2{ font-size:30px; padding-bottom: 5px; padding-top: 3px;}
h3{ font-size:22px; }
h4{ font-size:18px;}
h5{ font-size:14px; line-height: 1.5; }
h6{ font-size:12px; }

.font-size-72px { font-size:72px; }
.font-size-48px { font-size:48px; }
.font-size-46px { font-size:46px; }
.font-size-24px { font-size:24px; }
.font-size-18px { font-size:18px; }
.font-size-16px { font-size:16px; }
.font-size-14px { font-size:14px; }
.font-size-12px { font-size:12px; }
.font-size-10px { font-size:10px; }

.font { font-family: "OpenSansLight", Arial, Helvetica,sans-serif; }
.bold { font-family: "OpenSansBold", Arial, Helvetica,sans-serif; }
.bold-extra { font-family: "OpenSansExtrabold", Arial, Helvetica,sans-serif; }
.italic { font-family: "OpenSansItalic", Arial, Helvetica,sans-serif; }
.regular { font-family: "OpenSansRegular", Arial, Helvetica,sans-serif; }
.icon { font-family: "EntypoRegular", Arial, Helvetica,sans-serif; }

::-moz-selection { background: #fe57a1; color: #fff; text-shadow: none; }
::selection { background: #fe57a1; color: #fff; text-shadow: none; }

a {text-decoration: none; }
a:focus { outline: 0px none; }
a:hover, a:active { outline: 0px none; }

.clearfix:before, .clearfix:after { content: ""; display: table; }
.clearfix:after { clear: both; }
.clearfix { *zoom: 1; }
.clear {clear:both; }

.display-none { display:none; }
.next-p { margin-top:20px; }

.fullwidth { float:left; }

.copyright{vertical-align: top;}
.gig-time { width:150px; float:left;}
.gig-title {width:280px; float:left;
    position: relative;
    display: inline-block;
    border: 1px solid green;
}


.playcontainer{
    position: relative;
//width: 237px;
//background: #e74c3c;
    background:#e7a23c;
    border: solid 0px #ffffff;
    height:315px;
    box-shadow: 3px 3px #888888;
}


.playcontainer-festival{
    position: relative;
//width: 237px;
//background: #e74c3c;
    background:#e7a23c;
    border: solid 0px #ffffff;
    height:270px;
    box-shadow: 3px 3px #888888;
}

.playcontainerfestival{
    position: relative;
//width: 237px;
//background: #e74c3c;
    background:#eeeeee;
    border: solid 0px #dddddd;
//height:100%;
    box-shadow: 2px 2px #888888;
}

.playcontainereventpage{
    position: relative;
//width: 237px;
//border: 0px #dddddd;

}

.playcontainereventpage .play-icon{

}

.regulation-youtube {
  background-image: url('../images/yt_icon_mono_light.png');
  background-size: 60px 40px;
  cursor: pointer;
  position: absolute;
  top : 50%;
  left : 50%;
  transform: translate(-50%, -50%);
  width:60px;
  height:40px;
}

.regulation-youtube:hover {
  background-image: url('../images/yt_icon_rgb_small.png');
  background-size: 60px 40px;
  cursor: pointer;
  position: absolute;
  top : 50%;
  left : 50%;
  transform: translate(-50%, -50%);
  width:60px;
  height:40px;
}


.playcontainereventpage div {
    font-size: 12px;
    padding: 0px;
}

.playcontainer thumbimage{

}

.playcontainer div {

    font-size: 19px;
    padding: 8px;

}

.playcontainer h4 {
    color:#ffffff;
    background-color: #000000;
    position:absolute;
    margin:0;
    padding: 1px;
    top: 0px;
}

.playcontainer h5 {

    position:absolute;
    margin:0;
    padding: 5px;
    top: 75%;
}



.playcontainer-festival .play-icon{
    cursor: pointer;
    position: absolute;
    top : 50%;
    left : 50%;
    transform: translate(-50%, -50%);

}


.playcontainer-festival  div {

    font-size: 19px;
    padding: 8px;

}

.playcontainer-festival  h4 {
    color:#ffffff;
    background-color: #000000;
    position:absolute;
    margin:0;
    padding: 1px;
    top: -11px;
}

.playcontainer-festival  h5 {

    position:absolute;
    margin:0;
    padding: 5px;
    top: 75%;
}

.playcontainer-festival  .play-icon{
    cursor: pointer;
    position: absolute;
    top : 30%;
    left : 50%;
    transform: translate(-50%, -50%);
}

svg:hover #play-svg{
    fill: #CC181E;
}

.gig-details { width:100px; float:left; }
.gig-venue { width:230px; float:left; }
.gig-festival { width:420px; float:left; }
.gig-tickets {width:200px; float:left;}
.gig-play { width:30px; float:left; padding:0px; vertical-align: top}
.gig-playvideo { width:120px; float:left; padding:0px; vertical-align: top}
.search-menu { width:32%;}
.one-half { width:49%; }
.one-third { width:32%; }
.two-third { width:66%; }
.one-fourth { width:23.5%; }
.three-fourth { width:74.5%; }
.one-fifth { width:18.4%; }
.two-fifth { width:36.8%; }
.three-fifth { width:61.2%; }
.four-fifth { width:79.6%; }
.one-sixth { width:15%; }
.five-sixth { width:83%; }
.ticket-view { width:100%;
    background:#eeeeee;
    border: solid 0px #ffffff;
    box-shadow: 1px 1px #888888;
    margin-bottom:12px;
}
.share-view {
    margin-top: 12px;
    margin-bottom:12px;
    border: solid 1px #ff6600;
}
.one-half,.one-third,.one-third-event,.two-third,.three-fourth,.one-fourth,.one-fifth,.two-fifth,.three-fifth,.four-fifth,.one-sixth,.five-sixth,.ticket-view { position:relative; margin-right:2%; float:left; }
.last{ margin-right:0 !important; clear:right; }

.one-third .tickets { top:3px; left:3px; right:3px; bottom:3px;}

.ticket-view .tickets { margin-left:4px; margin-bottom:17px; position:relative; margin-top:17px; margin-right:3px;}

.center-wrapper { max-width:1150px; margin:0 auto; position:relative; }
.main-content-wrapper { margin-top:-60px; z-index:3; position: relative; }
.main-content { position:relative; margin-bottom:20px; display:table; width:100%; }
.main-content h2 { text-shadow: 0 0 1px rgba(0,0,0,0.3); }
.main-content-link-absolute { position:absolute; right:0; }

.sidebar-content { margin-bottom:50px; position:relative; margin-top:8px;}
.sidebar-content  h2 { text-shadow: 0 0 1px rgba(0,0,0,0.3); }

.button-small .align-middle { vertical-align: middle;}

.button-small { padding: 5px 8px !important; box-sizing: border-box; -ms-box-sizing: border-box; -moz-box-sizing: border-box; -webkit-box-sizing: border-box;}
.button-normal { padding: 10px 13px; display:table; box-sizing: border-box; -ms-box-sizing: border-box; -moz-box-sizing: border-box; -webkit-box-sizing: border-box; position:relative; }
.button-submit {  padding: 11px 15px; display:table; font-size: 18px; box-sizing: border-box; -ms-box-sizing: border-box; -moz-box-sizing: border-box; -webkit-box-sizing: border-box; position:relative; }

.button-no-tickets { padding: 2px 2px !important; box-sizing: border-box; -ms-box-sizing: border-box; -moz-box-sizing: border-box; -webkit-box-sizing: border-box; }

.button-back { width:95%; margin: 4px; border-radius: 3px;  text-decoration: none;   padding: 6px; font-size: 16px;   transition: .3s;   -webkit-transition: .3s;  -moz-transition: .3s;  -o-transition: .3s;  display: inline-block; }

.button-normal .button-detail { position:absolute; top:0; right:0; bottom:0; width:48px; }
.button-submit .button-detail { position:absolute; top:0; right:0; bottom:0; width:48px; }
.button-nav { padding: 14px 18px; display:table; box-sizing: border-box; -ms-box-sizing: border-box; -moz-box-sizing: border-box; -webkit-box-sizing: border-box; position:relative; }
.button-normal .button-detail span { margin:0 auto; display:table; margin-top:7px; }
.button-submit .button-detail span { margin:0 auto; display:table; margin-top:7px; }

.pagination  { margin:0 auto; display:table; }
.pagination li { float:left; margin-left:10px; }
.pagination li a { padding-top:7px; display:table; }
.pagination .border { border-width:1px; border-style:solid; height:39px; width:39px; box-sizing: border-box; -ms-box-sizing: border-box; -moz-box-sizing: border-box; -webkit-box-sizing: border-box; text-align:center;  }
.pagination .border:hover, .pagination .active { border:none; }

.pagination .more { pointer-events: none; cursor: default; }

.main-content-previous-next { margin-top:35px; width:100%; display:table; }
.main-content-previous-next .main-content-previous { float:left; }
.main-content-previous-next .main-content-next { float:right; }

.myForm { width:100%; margin-top: 10%; }
.myForm input[type=text] input[type=text] { width:30px; outline:none; webkit-appearance: none; -webkit-border-radius: 0; margin-bottom:15px; padding: 12px 13px; box-sizing: border-box; -ms-box-sizing: border-box; -moz-box-sizing: border-box; -webkit-box-sizing: border-box; border-width: 1px; border-style:solid; font: 12px "OpenSansRegular", Arial, Helvetica,sans-serif; }
.myForm input[type=submit]  { border: none; outline:none; cursor:pointer; -webkit-appearance: none; -webkit-border-radius: 0;  width:100%; text-align:left; font: 12px "OpenSansRegular", Arial, Helvetica,sans-serif; }

.comment-form { margin-top:15px; }
.comment-form input[type=submit]  { margin-top:3px; width:272px; border: none; outline:none; cursor:pointer; -webkit-appearance: none; -webkit-border-radius: 0; text-align:center; font: 14px "OpenSansRegular", Arial, Helvetica,sans-serif; }
.comment-form input[type=text] { color:#212121; margin-top:2px; width:272px; padding: 10px 13px;  display:table; box-sizing: border-box; -ms-box-sizing: border-box; -moz-box-sizing: border-box; -webkit-box-sizing: border-box; position:relative; font: 12px "OpenSansRegular", Arial, Helvetica,sans-serif;  }
.comment-form textarea { border-width: 1px; border-style:solid; outline:none; webkit-appearance: none; -webkit-border-radius: 0; font: 12px "OpenSansRegular", Arial, Helvetica,sans-serif; box-sizing: border-box; -ms-box-sizing: border-box; -moz-box-sizing: border-box; -webkit-box-sizing: border-box; padding: 12px 13px; width:100%; height:163px; }

.comments-ul li { margin-top:35px; position:relative; display:table; }
.comments-ul .comment-icon { -moz-border-radius: 30px; -webkit-border-radius: 30px; border-radius: 30px; -moz-background-clip: padding; -webkit-background-clip: padding-box; background-clip: padding-box; width:60px; height:60px; display:table; text-align:center; padding-top:12px; box-sizing: border-box; -ms-box-sizing: border-box; -moz-box-sizing: border-box; -webkit-box-sizing: border-box; float:left; margin-right:25px; }
.comments-ul .comment-content { float:left;  }
.comments-ul .comment-content .name { width:100%; display:table; }
.comments-ul .comment-content .date { margin-bottom:10px; display:table; }
.comments-ul .comment-content .button-small { margin-top:10px; display:table; padding: 0 8px !important; }

.comments-ul .reply-1 { margin-left:85px; }
.comments-ul .reply-2 { margin-left:170px; }
.comments-ul .comment-detail { position:absolute; top:13px; left:-70px; }

.info-button-box { margin:15px 0; display:table; width:100%; }
.info-button-box .button-normal { width:100%; margin-bottom:10px; }

.google-map { margin-top:40px; height:500px; width:100%; }

.contact-form { margin-top:35px; }
.contact-form  input[type=submit]  { margin-top:11px; display:table; border: none; outline:none; cursor:pointer; -webkit-appearance: none; -webkit-border-radius: 0;  width:100%; text-align:left; font: 12px "OpenSansRegular", Arial, Helvetica,sans-serif; }
.contact-form  input[type=text] { width:100%; outline:none; webkit-appearance: none; -webkit-border-radius: 0; margin-bottom:15px; padding: 12px 13px; box-sizing: border-box; -ms-box-sizing: border-box; -moz-box-sizing: border-box; -webkit-box-sizing: border-box; border-width: 1px; border-style:solid; font: 12px "OpenSansRegular", Arial, Helvetica,sans-serif; }
.contact-form  textarea { border-width: 1px; border-style:solid; outline:none; webkit-appearance: none; -webkit-border-radius: 0; font: 12px "OpenSansRegular", Arial, Helvetica,sans-serif; box-sizing: border-box; -ms-box-sizing: border-box; -moz-box-sizing: border-box; -webkit-box-sizing: border-box; padding: 12px 13px; width:100%; height:163px; }

.alert { width:100%; text-align:center; margin:0 0 10px 0 !important; }

.toggle { display:table; width:100%; }
.toggle .button-normal { width:100%; float:none !important; margin:0 0 10px 0 !important; border:none; }
.toggle .icon { float:none !important; margin-top:0 !Important; }
.toggle .toggle-content { padding:15px; box-sizing: border-box; -ms-box-sizing: border-box; -moz-box-sizing: border-box; -webkit-box-sizing: border-box; margin: -10px 0 5px 0; width:100%; display:none; }

/****************************************************************************
4. TOP WRAPPER
****************************************************************************/

.top-wrapper { width:100%; display:table; position:relative; }
.big-wrapper { height:430px; margin-top:0px;}
.small-wrapper { height:310px; }
.register-wrapper { height:120px; }
.festival-wrapper { height:360px; }

.top-wrapper-mask { width:100%; height:100%; position:absolute; }
.top-wrapper .center-wrapper { height:100%; }
.top-wrapper .top-content-position { max-width:1150px; margin-top:18px; z-index:5; }
.top-wrapper .top-content .top-content-up { border-width: 0 0 2px 0; position:relative; width:100%; z-index:5; }
.top-wrapper .top-content .top-content-up .logo { position:absolute; margin-top: 2px; bottom:25px; left:0; }
.top-wrapper .top-content .top-content-up .main-menu { position:absolute; bottom:17px; right:0; }
.top-wrapper .top-content .top-content-up .main-menu li { display:inline; margin-left:20px; position:relative; z-index:101; }
.top-wrapper .top-content .top-content-up .main-menu li ul { position:absolute; top:13px; z-index:9999; left:-20px; display:none; padding-top:32px;   }
.top-wrapper .top-content .top-content-up .main-menu li ul li  { display:table;  width:250px; }
.top-wrapper .top-content .top-content-up .main-menu li ul li a { padding: 5px 10px; width:100%; display:table; box-sizing: border-box; -ms-box-sizing: border-box; -moz-box-sizing: border-box; -webkit-box-sizing: border-box;  }
.top-wrapper .top-content .top-content-up .main-menu li:hover > ul { display:inline; }


.top-wrapper .top-content .top-content-up .mobile-button { position:absolute; right:0; top:-25px;bottom:24px; cursor:pointer; display:none; }

.top-wrapper .top-content .top-content-up .mobile-menu { position:absolute; left:0; right:0; top:50px; display:none; }
.top-wrapper .top-content .top-content-up .mobile-menu a { width:100%; display:table; padding: 5px 8px; box-sizing: border-box; -ms-box-sizing: border-box; -moz-box-sizing: border-box; -webkit-box-sizing: border-box; }

.top-wrapper .top-content .top-content-down { border-width: 1px 0 0 0; position:relative; width:100%; margin-top:6px; z-index:3;  }
.top-wrapper .top-content .top-content-down .social { float:right; margin-top:10px; position:relative; }
.top-wrapper .top-content .top-content-down .social  li { height:25px; width:25px; float:left; margin-left:5px; }
.top-wrapper .top-content .top-content-down .social  li a { width:100%; height:100%; display:table; }
.top-wrapper .top-content .top-content-down .social  li img { width:60%; margin:0 auto; display:table; margin-top:5px; }
.top-wrapper .top-content .top-content-down .top-content-down-text { margin-top:10px; float:left; }
.top-wrapper .top-content .top-content-down .top-content-down-text a:hover { text-decoration:underline; }

.top-wrapper .bottom-background-position { width:100%; position:absolute; bottom:0; z-index:3; }
.top-wrapper .bottom-background { max-width: 1193px; margin:0 auto; height:60px; }

.header-background-1 { background: url(../images/headers/compressed1.jpg) no-repeat; color:#000000; }

.header-background-2 { background: url(../images/headers/music-festival.jpg) no-repeat; color:#ffffff; }

.header-background-3 { background: url(../images/headers/festivalgreen.jpg) no-repeat; color:#ffffff; }

.header-background-4 { background: url(../images/headers/festival-crowd-big.jpg) no-repeat; color:#ffffff; }

.header-background-5 { background: url(../images/headers/festival-green-large.jpg) no-repeat; color:#ffffff; }

.header-background-7 { background: url(../images/headers/bluedot-banner2.gif) no-repeat; color:#000000; }

.slider-wrapp {  position:absolute; display:table; height:615px; top:0; width:100%; z-index:2; }
.slider { height:615px !important;  }
.slider h1 { margin:372px 0 25px; }
.slider p { width:70%; }
.slider .button-small { position:absolute; top:-52px; }

.top-content { position:relative; height:100%; width:100%; display:table; }
.top-content h1 { text-align:center; margin-top:40px; }
.top-content h3 { text-align:center; margin-top:26px; }

.top-content p { text-align:center; width:80%; margin:0 auto; margin-top:25px; }
.top-content .button-normal { margin-top:3px; width:273px; }
.top-content .button-1 { position:absolute; bottom:323px; left:291px; display:inline; z-index:4; }
.top-content .button-2 { position:absolute; bottom:323px; right:296px; display:inline; }
.just-text h1 { margin-top:127px; }

.top-content .three-fourth h1 { text-align:left; margin-bottom:15px;}
.top-content .three-fourth p { text-align:left; width:100%; }
.top-content .one-fourth img { margin-top:91px; }

.album-wall { max-width:1150px; margin:0 auto; }
.album-wall .one-fourth { margin-top:193px; position:relative; }
.album-wall img { width:273px; height:273px; }
.album-wall .center-left img { margin-left:-2px; }
.album-wall .center-right img { margin-left:-5px; }
.album-wall .img-hover-media-top { position:absolute; top:0;  width:273px; height:273px; opacity:0; }
.album-wall .img-hover-media-top  a { width:100%; height:100%; display:table; position:relative; }
.album-wall .img-hover-media-top  span { margin:0 auto; display:table; }
.album-wall .img-hover-media-top  h6 { position:absolute; bottom:0; left:0; right:0; text-align:center; padding: 0 15px; }
.album-wall .left-hover { left:-2px; }
.album-wall .right-hover { left:-5px; }

.album-wall .img-z-index { z-index:3; position:relative; }
.album-wall .img-hover-media-top-z-index { z-index:4; }

/****************************************************************************
5. NEWS
****************************************************************************/

.news { position:relative; margin-top:35px; }
.news img { width:100%; height:160px; }
.news h4 { margin-top:11px; text-shadow: 0 0 1px rgba(0,0,0,0.3); }
.news h4:hover { text-shadow: 0 0 1px rgba(0,0,0,0.0); }
.news p { margin-top:10px; }
.news .img-hover { height:160px; width:100%; position:absolute; top:0; opacity:0; }
.news .img-hover a { width:100%; height:100%; display:table; }
.news .img-hover span { margin:0 auto; display:table; }

.news-wide img { float:left; margin-right:18px; width:300px; }
.news-wide { clear:both; display:table; }
.news-wide .img-hover { width:273px; }

.news-open .news-open-img { width:100%; height:100%; }
.news-open p { margin-top:20px; }
.news-open-h2 { margin:10px 0; }
.news-open-big-header { margin-top:-20px; }



.search-box { padding:5px; }
.search  input[type=text] { border:none; outline:none; height:10px; padding: 0 10px; box-sizing: border-box; -ms-box-sizing: border-box; -moz-box-sizing: border-box; -webkit-box-sizing: border-box; webkit-appearance: none; -webkit-border-radius: 0; }
.search  input[type=submit] { background:#fff; border:none; outline:none; box-sizing: border-box; -ms-box-sizing: border-box; -moz-box-sizing: border-box; -webkit-box-sizing: border-box; webkit-appearance: none; -webkit-border-radius: 0; position:absolute; bottom:18px; right:9px; cursor:pointer; }

/****************************************************************************
9. EVENTS
****************************************************************************/

.event { position:relative; margin-top:14px; }
.event img {

}

.event-image {
  width: 100%;
  height: 160%;
}

.event-image:hover {
  width: 100%;
  height: 160%;
  cursor: pointer;
}
/****************************************************************************
10. PHOTOS
****************************************************************************/

.photo { position:relative; margin-top:35px; }
.photo  img { width:100%;  height:100%; }
.photo  h4 { margin-top:11px; text-shadow: 0 0 1px rgba(0,0,0,0.3); }
.photo  h4:hover { text-shadow: 0 0 1px rgba(0,0,0,0.0); }
.photo  p { margin-top:10px; }
.photo  .img-hover { height:160px; width:100%; position:absolute; top:0; opacity:0; }
.photo  .img-hover a { width:100%; height:100%; display:table; }
.photo  .img-hover span { margin:0 auto; display:table; }

.photo-slider{ height:420px !important; margin-top:35px; }
.photo-slider h3 { margin:300px 26px 10px; }
.photo-slider p { width:80%; margin-left:25px; }

.photo-open { margin-top:18px; }
.photo-no-slider .photo-open { margin-top:35px; }

/****************************************************************************
11. VIDEOS
****************************************************************************/

.video-box { position:relative; margin-top:35px; }
.video-box img { width:100%;  height:160px; }
.video-box h4 { margin-top:11px; text-shadow: 0 0 1px rgba(0,0,0,0.3); }
.video-box h4:hover { text-shadow: 0 0 1px rgba(0,0,0,0.0); }
.video-box  p { margin-top:10px; }
.video-box .img-hover { height:160px; width:100%; position:absolute; top:0; opacity:0; }
.video-box .img-hover a { width:100%; height:100%; display:table; }
.video-box .img-hover span { margin:0 auto; display:table; }
.video-box  .video-iframe { height:160px; width:100%; border:none; border-width:0; }

.video-open { width:100%; height:400px; }


/****************************************************************************
13. NORMAL PAGE
****************************************************************************/

.normal-page .one-fifth, .normal-page .one-third, .normal-page .one-third-event,.normal-page .two-third, .normal-page .one-forth, .normal-page .three-fourth, .normal-page .one-half, .normal-page .one-one { margin-top:35px; display:table; }

.normal-page .photo { margin-top:0; }
.normal-page  img { width:100%;  height:160px; }
.normal-page .img-hover { height:160px; width:100%; position:absolute; top:0; opacity:0; }
.normal-page  .img-hover a { width:100%; height:100%; display:table; }
.normal-page  .img-hover span { margin:0 auto; display:table; }

.normal-page .one-one { width:100%; float:left; }
.normal-page h4 { margin-bottom:35px; }
.normal-page .icon { float:left; margin:5px; height:44px; width:45px; display:table; text-align:center; box-sizing: border-box; -ms-box-sizing: border-box; -moz-box-sizing: border-box; -webkit-box-sizing: border-box; padding-top:4px; cursor:pointer; }
.normal-page .button-normal, .normal-page .button-small  { float:left; margin:5px; }

/****************************************************************************
15. Tweeter
****************************************************************************/
/*
.tweeter { border-width: 1px; border-style:solid; padding: 30px 20px; display:table; width:100%; box-sizing: border-box; -ms-box-sizing: border-box; -moz-box-sizing: border-box; -webkit-box-sizing: border-box;  }
.tweeter img { float:left; margin-right:20px; }
.tweeter .twitter-header { float:left; margin-right:5px; }
.tweet { float:left; margin-top:-24px; }
.tweet .tweet_time { display:none; }
.tweet .loading { margin-top:24px; }

/****************************************************************************
16. BOTTOM
****************************************************************************/

.bottom { display:table; width:100%; padding:50px 0; position:relative; }
.bottom  h2 { margin-bottom:50px; text-shadow: 0 0 1px rgba(255,255,255,0.3); }
.bottom ul { margin-top:25px; }
.go-top { width:34px; height:34px; border-width:2px; border-style:solid; position:absolute; right:50px; bottom:100px; display:table; opacity:0;
    -moz-border-radius: 17px 17px 17px / 17px;
    -webkit-border-radius: 17px 17px 17px / 17px;
    border-radius: 17px 17px 17px / 17px;
    -moz-background-clip: padding;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
}
.go-top a { width:34px; height:34px; display:table; }
.go-top img { margin:0 auto; display:table; margin-top:13px; width:14px; height:8px; }


/****************************************************************************
17. FOOTER
****************************************************************************/

.footer { display:table; width:100%; padding: 15px 0; box-sizing: border-box; -ms-box-sizing: border-box; -moz-box-sizing: border-box; -webkit-box-sizing: border-box; }
.footer span { float:left; }
.footer ul { float:right; }
.footer ul li { display:inline; }

/****************************************************************************
18. WIDGETS
****************************************************************************/

.widget-bottom-latest-posts li { border-width: 0 0 1px 0; border-style:solid; padding:8px 0; }

.widget-bottom-tags .button-small { float:left; margin: 0 5px 5px 0; }

.widget-bottom-flickr ul li { float:left; margin: 0 20px 20px 0 }
.widget-bottom-flickr ul li img { width:70px; height:70px; }

.widget-sidebar-media-player ul { margin-top:25px; }
.widget-sidebar-media-player ul li { border-width: 0 0 1px 0; border-style:solid; padding: 10px 0; }
.widget-sidebar-media-player ul li div { -moz-border-radius: 25px; -webkit-border-radius: 25px; border-radius: 25px; -moz-background-clip: padding; -webkit-background-clip: padding-box; background-clip: padding-box; border-width: 2px; border-style:solid; width:20px; height:20px; position:relative; float:right; }
.widget-sidebar-media-player ul li div span { margin:0 auto; margin-top:-5px; display:table; }
.widget-sidebar-media-player ul li a { width:100%; display:table; }
.widget-sidebar-media-player .button-small { float:left; margin:25px 10px 0 0; }
.widget-sidebar-media-player .price { margin-top:30px; display:table; }

.widget-sidebar-media-player .album-info { margin-top:35px; display:table; width:100%; }
.widget-sidebar-media-player img { width:100%; height:130px; }
.widget-sidebar-media-player .first-half { padding-right:20px; box-sizing: border-box; -ms-box-sizing: border-box; -moz-box-sizing: border-box; -webkit-box-sizing: border-box; }
.widget-sidebar-media-player h6 { margin-bottom:10px; }
.widget-sidebar-media-player .last { position:relative;; }
.widget-sidebar-media-player .last .img-hover-sidebar { height:130px; width:100%; position:absolute; top:0; opacity:0; }
.widget-sidebar-media-player .last .img-hover-sidebar a { width:100%; height:100%; display:table; }
.widget-sidebar-media-player .last .img-hover-sidebar span { margin:0 auto; display:table; }

.media-player-promo img { width:100%; height:270px !important; }
.media-player-promo { margin-top:-136px; position:relative }
.media-player-promo .img-hover-media-player { height:270px; width:100%; position:absolute; top:0; opacity:0; }
.media-player-promo .img-hover-media-player a { width:100%; height:100%; display:table; }
.media-player-promo .img-hover-media-player span { margin:0 auto; display:table; }
.media-player-promo h4 { margin-top:12px; }

.widget-sidebar-other-albums .album-info { margin-top:35px; display:table; width:100%; }
.widget-sidebar-other-albums img { width:100%; height:130px; }
.widget-sidebar-other-albums .first-half { padding-right:20px; box-sizing: border-box; -ms-box-sizing: border-box; -moz-box-sizing: border-box; -webkit-box-sizing: border-box; }
.widget-sidebar-other-albums h6 { margin-bottom:10px; }
.widget-sidebar-other-albums .last { position:relative;; }
.widget-sidebar-other-albums .last .img-hover-sidebar { height:130px; width:100%; position:absolute; top:0; opacity:0; }
.widget-sidebar-other-albums .last .img-hover-sidebar a { width:100%; height:100%; display:table; }
.widget-sidebar-other-albums .last .img-hover-sidebar span { margin:0 auto; display:table; }

.widget-sidebar-text-widget p { margin-top:35px; }

.widget-sidebar-popular-artist .artist-info { margin-top:35px; display:table; width:100%; }
.widget-sidebar-popular-artist img { width:100%; height:130px; }
.widget-sidebar-popular-artist .first-half { padding-right:20px; box-sizing: border-box; -ms-box-sizing: border-box; -moz-box-sizing: border-box; -webkit-box-sizing: border-box; }
.widget-sidebar-popular-artist h6 { margin-bottom:10px; }
.widget-sidebar-popular-artist .last { position:relative;; }
.widget-sidebar-popular-artist .last .img-hover-sidebar { height:130px; width:100%; position:absolute; top:0; opacity:0; }
.widget-sidebar-popular-artist .last .img-hover-sidebar a { width:100%; height:100%; display:table; }
.widget-sidebar-popular-artist .last .img-hover-sidebar span { margin:0 auto; display:table; }

.widget-sidebar-search  .search-box{ margin-top:35px; }

.widget-sidebar-submenu ul { margin-top:25px; }
.widget-sidebar-submenu ul li { border-width: 0 0 1px 0; border-style:solid; padding: 10px 0; }

/****************************************************************************
19. MUSIC PLAYER
****************************************************************************/
/*
#fap-wrapper-switcher { border:none !important; top:-16px !important; -moz-border-radius-topleft: 0px !important; -moz-border-radius-topright: 0px !important; -moz-border-radius-bottomright: 0px !important; -moz-border-radius-bottomleft: 0px !important; -webkit-border-radius: 0px 0px 0px 0px !important; border-radius: 0px 0px 0px 0px !important; }
#fap-current-cover, #fap-cover-replacement { border:none !important; -webkit-border-radius: 0px !important; -moz-border-radius: 0px !important; border-radius: 0px !important; -webkit-box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.5) !important; -moz-box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.5) !important; box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.5) !important; }

/****************************************************************************
20. RESPONSIVE
****************************************************************************/
.registration-form {
    width:40%;
    align-content: center;
    height:100%;
}
@media all and ( max-width: 1350px ) {

    .camera_prev { left:20px !important; }
    .camera_next { right:20px !important; }
    .go-top { display:none; }
}

@media all and ( max-width: 1200px ) {

    .center-wrapper, .album-wall { padding: 0 20px; }
    .main-content img, .album-wall img, .sidebar-content img, .top-content img  { height:auto !important; }
    .img-hover, .img-hover-sidebar, .img-hover-media-player, .img-hover-album, .album-wall .img-hover-media-top, .img-hover-resident { background-color: rgba(0,0,0,0) !important; }
    .img-hover span, .img-hover-sidebar span, .img-hover-media-player span, .img-hover-album span, .album-wall .img-hover-media-top span, .img-hover-resident span, .album-wall .img-hover-media-top h6 { display:none !important; }
    .main-content .one-third,.main-content .one-third-event,.main-content .event-wide , .main-content .news-wide { float:none; display:inline-block; vertical-align: top; }
    .shop-cart-button { right:0; }
    .album-wall img, .top-content .one-fourth img  { width:100%; }
    .center-wrapper .button-1  { left:50px !important; right:50px !important; width:auto !Important; }
    .center-wrapper .button-2  { display:none; }
    .normal-page .one-fifth, .normal-page .one-third, .normal-page .one-third-event,.normal-page .two-third, .normal-page .one-fourth, .normal-page .three-fourth, .normal-page .one-half, .normal-page .one-one, .comment-form .one-third, .one-third-event,.comment-form .two-third, .contact-form .one-third,.one-third-event,.contact-form .two-third { float:left; }
    .photo-slider { height:300px !important; }
    .photo-slider .center-wrapper { position:absolute; bottom:25px !important; }
    .sidebar-content .one-half { width:100%; }
    .sidebar-content .last { margin-top:25px; }
    .search  input[type=text] { width:100% !important; }
    .search .icon { display:none; }
}

@media all and ( max-width: 950px ) {

    .registration-form { width:99%; }
    .mobile-button { display:inline !important; }
    .main-menu { display:none; }
    .photo-slider { height:300px; }
    .top-wrapper .slider .center-wrapper { left:80px; right:80px; bottom:150px !important;  }
    .top-wrapper h1 { line-height:52px; }
}

@media all and ( max-width: 850px ) {
    .registration-form { width:99%; }
    .bottom .one-fourth { width:100%; margin-bottom:50px; }
    .bottom .last { margin-bottom:0; }
}

@media all and ( max-width: 750px ) {
    .main-content-wrapper .main-content-responsive, .main-content-wrapper .sidebar-responsive { width:100%;}
    .footer ul { float:left; width:100%; }
    .main-content .one-third,.main-content.one-third-event,.normal-page .one-fifth, .normal-page .one-third, .normal-page .two-third, .normal-page .one-fourth, .normal-page .three-fourth, .normal-page .one-half, .normal-page .one-one, .comment-form .one-third, .comment-form .two-third, .contact-form .one-third, .contact-form .two-third { width:100%; float:left; }
    .center-wrapper .button-1, .top-wrapper .album-wall  { display:none; }
    .news-wide img { float:none; width:100%; }
    .top-wrapper .three-fourth { margin-top:-50px; }
    .top-wrapper .one-fourth { margin-top:-50px; }
    .media-player-promo { margin-top:0; }
    .mobile-has-player { margin-top:82px; }
}

@media all and ( max-width: 600px ) {
    .registration-form { width:99%; }
    .comment-form .one-third, .comment-form .two-third, .contact-form .one-third, .contact-form .two-third { width:100%; }
    .contact-form textarea, .comment-form textarea { margin-top:15px; }
    .comments-ul .reply-1, .comments-ul .reply-2 { margin-left:0; }
    .top-wrapper  h1 { font-size:28px; line-height:32px; }
    .top-wrapper  .font-size-16px { font-size:11px; }
    .top-wrapper .font-size-24px { font-size:19px; }
    .top-wrapper .slider .center-wrapper { left:50px; padding-right:50px;   }
}

@media all and ( max-width: 450px ) {
    .registration-form { width:99%; }
    .main-content-link-absolute { display:none; }
    .top-wrapper .top-content-down-text { display:none; }
    .top-wrapper .three-fourth { margin-top:-75px; }
    .top-wrapper .one-fourth { margin-top:-75px; }
    .album-open .album-img-place .album-img-center, .resident-open .resident-img-place .resident-img-center { width:100% !important; padding: 0 20px; box-sizing: border-box; -ms-box-sizing: border-box; -moz-box-sizing: border-box; -webkit-box-sizing: border-box; }
}

@media all and ( max-width: 350px ) {
    .registration-form { width:99%; }
    .top-wrapper  h1 { font-size:16px; line-height:32px; }
}



/* Front end changes */
.video-container { position: relative; padding-bottom: 56.25%; padding-top: 30px; height: 0; overflow: hidden; }

.video-container iframe, .video-container object, .video-container embed { position: absolute; top: 0; left: 0; width: 100%; height: 100%; }
myForm {
    margin-top:10px;

    position: relative;
    alignment-adjust: middle
}

.fb-page {
    z-index: 5;
    position: relative;
}
.sidebar-responsive {
    padding-top: 0;
}

.playcontainer .play-icon {
    cursor: pointer;
    position: relative;
    top: 50%;
    left: 50%;
    transform: none;
    margin: -50% auto 30%;

}





.player-wrapper {
    position:relative;
    padding:0px;
    border:0px;

}



.overlay,
#overlay-shade {
    display: none;
}

#overlay-shade {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    background-color: #000;
}

.overlay {
    display:none;
//width: 70%;
//min-height: 70%;
    z-index: 10000;
    background-color: #000000;
    border: 2px solid #CFCFCF;
    color: #fff;
    box-shadow: 0 0 16px #000;
} .ie7 .overlay {
      height: 200px;
  } .overlay .wrapper {
        padding: 0px 0px 0px;
    }


.overlay .toolbar {
    padding: 2px;
    line-height: 1;
    text-align: right;
    overflow: hidden;
} .overlay .toolbar a.close {
      display: inline-block;
      *display: inline;
      zoom: 1;
      padding: 0 8px;
      font-size: 12px;
      text-decoration: none;
      font-weight: bold;
      line-height: 18px;
      border-radius: 5px;
      -webkit-border-radius: 5px;
      -moz-border-radius: 5px;
      -o-border-radius: 5px;
      color: #ffffff;
      background-color: #48aa25;
  } .overlay .toolbar a.close span {
        color: #fff;
    } .overlay .toolbar a.close:hover,
      .overlay .toolbar a.close:hover span {
          background-color: #b90900;
          color: #fff;
      }

.overlay {
    position: fixed;
    margin: 0 !important;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%); /* ie9 */
    -webkit-transform: translate(-50%, -50%); /* safari iOS - older androids */
    transform: translate(-50%, -50%); /* all browsers */
}


#popup-trigger {padding: 15px; display:table; box-sizing: border-box; -ms-box-sizing: border-box; -moz-box-sizing: border-box; -webkit-box-sizing: border-box; position:relative; }
.popup {
    text-align: center;
    display: none;
    position: fixed;
    margin: 0 !important;
    width:40%;
    height:30%;
    top: 30%;
    left: 20%;
    z-index: 10000;
    background-color: #ffffff;
    border: 1px solid #CFCFCF;
    padding: 2px 2px 2px 2px;
    box-shadow: 0 0 16px #000;
}


.popup-mobile {position: relative; top: 0; left: 0; margin: 30px 0 0; width: 100%;}
.popup-btn-close {position: absolute; top: 8px; right: 14px; color:#df5647; font-weight: bold; text-transform: uppercase; cursor: pointer;}

.social-button { display: inline-block;

    height:55px;
    width:75px;

}

.social-tickets { display: inline-block;
    justify-content: center;
    height:65px;
    width:150px;


}

.social-wrapper {
    display: flex;
    justify-content: center;
    padding: 20px 1px 1px 1px;
}

.social-wrapper-festival {
    display: flex;
    justify-content: center;
    padding: 5px 1px 1px 1px;
}
/**
 * AddToCalendar Base CSS
 * http://addtocalendar.com
 */

/* Base */

.addtocalendar var{
    display: none;
}

.addtocalendar {
    position: relative;
    display: inline-block;
    background: transparent!important;

}


.atcb-link {

}

.atcb-link:focus~ul,
.atcb-link:active~ul,
.atcb-list:hover{
    visibility:visible;
}

.atcb-list {
    visibility: hidden;
    position: absolute;
    top: 100%;
    left: 0;
    width: 170px;
    z-index: 900;
}

.atcb-list,
.atcb-item
{
    list-style: none;
    margin: 0;
    padding: 0;
    background: #fff;
}

.atcb-item {
    float: none;
    text-align: left;
}

.atcb-item-link
{
    text-decoration: #fff666;
    outline: none;
    display: block;
}

.atcb-item.hover,
.atcb-item:hover {
    position: relative;
    z-index: 900;
    cursor: pointer;
    text-decoration: none;
    outline: none;
}

.mc_embed_signup {
    background:#212121;
    font-size:30px
}

.top-content-down .searchbox {

    display:none;
    width:40%;
    margin: 0 auto;
}


header {
    padding:0px 0px 0px 0px;
    flex: 1 1 20%;
    width:100%;
}

.headlogo {
    display: block;
    margin: 0 auto;
    width:200px;
}

nav {
    position:absolute;
    top:35px;
    left:0px;
    background: #343434;
    padding:0px 0px 0px 0px;
    width:100%;
    height:1000px;
    transform: scale(1,0);
    transform-origin:top;
    transition: transform 400ms ease-in-out;
}

nav ul {
    list-style:none;
    margin:0;
    padding:0;
}

nav li { margin-bottom:0.5em;
    margin-left: 0.5em;

}

nav a { text-decoration: none;
    color:#ffffff;
    font-size:1.0rem;
    opacity:0;
    transition: opacity 150ms ease-in-out;

}

/*Hide checkbox*/
/*
input[type=checkbox]{
    display: none;
}

 */

.showcity { display:none;}

.showcity:hover {
    cursor:pointer;
}

.city {
    padding:10px 0px 0px 12px;
    font-family: 'OpenSansLight', Arial, serif;
    position: relative;
    font-size:1.0rem;
    display:block;
}

.city:hover {
    cursor:pointer;
}

.venues {
    padding:10px 0px 10px 12px;
    font-family: 'OpenSansLight', Arial, serif;
    position: relative;
    font-size:1.0rem;
    display:block;
}

.venues:hover {
    cursor:pointer;
}

/*
#city:checked ~ .showcity  {
    display: block;
    position:relative;
    float: none;
    transform:scale(1,1);
}

.showvenues {
    display:none;
}

#venues:checked ~ .showvenues {
    display:block;
    position:relative;
    float:none;
    transform:scale(1,1);
}
*/

nav a:hover { color:#ff6655; }

.nav-toggle:checked ~ nav {
    display:block;
    transform:scale(1,1);
}

.nav-toggle:checked ~ nav a {
    opacity: 1;
    transition: opacity 250ms ease-in-out 250ms;
}

.nav-toggle {
    display:none;
}

.nav-item {
    padding:3em;
    font-family: 'OpenSansLight', Arial, serif;
    text-decoration: none;
    color:#fff;
}

.nav-toggle-label {
    position:absolute;
    margin-left: 0em;
    display:flex;
    border:1px;
    font-size:1em;
    top:0;
    left:0;
    align-items: center;
}

.nav-toggle-label span,
.nav-toggle-label::before,
.nav-toggle-label::after {
    display:block;
    background:white;
    height:2px;
    width:2em;
    border-radius:2px;
    margin-top:15px;
    position:relative;
}

.nav-toggle-label::before,
.nav-toggle-label::after {
    content: '';
    position:absolute;
}

.nav-toggle-label::after {
    bottom:7px;
}

.nav-toggle-label::before {
    top:7px;
}


nav li ul {
    display:none;
}

/*Hide dropdown links until they are needed*/
nav li ul {
    display: none;
}
/*Make dropdown links vertical*/
nav li ul li {
    display: block;
    float: none;
}

li > a.collapse, li.expand > a.expand {
    display: none;
}
li.expand > a.collapse {
    display: inherit;
}
li > a.expand ~ ul {
    display: none;
}

li.expand > a.expand ~ ul {
    display: inherit;
}

.collapse {
    padding:0.3em;
    color:#ff6655;
}

/*Display the dropdown on hover*/
/*
nav ul li a:hover + .hidden, .hidden:hover {
    display: block;
    position:absolute;
    opacity:1.0;
    background-color: #343434;
    padding:0.5em;
    element.classList.remove("city");

}


nav ul li #close:hover + .hidden, .hidden:hover {
    display: none;
    position:absolute;
    opacity:1.0;
    background-color: #343434;
    padding:0.5em;
}
*/

@media (min-width: 800px) {

    .nav-toggle-label {
        display:none;
    }

    .headlogo {
        float:left;
    }

    nav {
        all:unset;
        display:inline-block;
    }

    nav ul {
        all:unset;
        display: flex;


    }

    nav li {
        all:unset;
        margin-left: 1em;
        margin-top: 0em;
        margin-bottom:0;
        cursor:pointer;
        font-size: 1.0em;

    }

    nav li ul li {
        margin-left: 0em;
        cursor:pointer;
    }

    nav li ul {
        display:none;
        margin-left: 0em;
    }

    nav a {
        all:unset;
        display: flex;
    }

    .nav-toggle {
        position:absolute;
    }

    /*
    .showcity { display:none;}
    */

    .showcity:hover {
        cursor:pointer;
    }

    .city {
        margin-left: 1em;
        margin-top: 0em;
        font-family: 'OpenSansLight', Arial, serif;
        font-size:0.8rem;
        display:block;
    }

    .city:hover {
        cursor:pointer;
    }


    .venues {
        margin-left: 1em;
        margin-top: 0em;
        font-family: 'OpenSansLight', Arial, serif;
        position: relative;
        opacity:0.9;
        font-size:0.8rem;
        display:block;

    }

    .venues:hover {
        cursor:pointer;
    }

    /*Hide dropdown links until they are needed*/
    nav li ul {
        display: none;
    }
    /*Make dropdown links vertical*/
    nav li ul li {
        display: block;
        float: none;
    }
    /*Prevent text wrapping */
    nav li ul li a {
        width: auto;
        min-width: 100px;

    }

    /*Display the dropdown on hover*/
    nav ul li a:hover + .hidden, .hidden:hover {
        display: block;
        position:absolute;
        opacity:1.0;
        background-color: #343434;
        padding:0.5em;

    }


}

.fos_user_registration_register { margin-top:35px; }
.fos_user_registration_register input[type=submit] { width:100%; padding: 11px 15px; display:table; font-size: 18px; box-sizing: border-box; -ms-box-sizing: border-box; -moz-box-sizing: border-box; -webkit-box-sizing: border-box; position:relative; }

.fos_user_registration_register  input[type=text] { width:100%; outline:none; webkit-appearance: none; -webkit-border-radius: 0; margin-bottom:15px; padding: 12px 13px; box-sizing: border-box; -ms-box-sizing: border-box; -moz-box-sizing: border-box; -webkit-box-sizing: border-box; border-width: 1px; border-style:solid; font: 12px "OpenSansRegular", Arial, Helvetica,sans-serif; }
.fos_user_registration_register  input[type=password] { width:100%; outline:none; webkit-appearance: none; -webkit-border-radius: 0; margin-bottom:15px; padding: 12px 13px; box-sizing: border-box; -ms-box-sizing: border-box; -moz-box-sizing: border-box; -webkit-box-sizing: border-box; border-width: 1px; border-style:solid; font: 12px "OpenSansRegular", Arial, Helvetica,sans-serif; }
.fos_user_registration_register  input[type=email] { width:100%; outline:none; webkit-appearance: none; -webkit-border-radius: 0; margin-bottom:15px; padding: 12px 13px; box-sizing: border-box; -ms-box-sizing: border-box; -moz-box-sizing: border-box; -webkit-box-sizing: border-box; border-width: 1px; border-style:solid; font: 12px "OpenSansRegular", Arial, Helvetica,sans-serif; }


.center {
    margin: auto;
    width: 50%;
    border: 3px solid green;
    padding: 10px;
}


